import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { worker } from "../../worker";
import CategoryRestDataTemp from "../../Temp/CategoryRestDataTemp";
import { generateRequest } from "./utils/generateRequest";
import { RenderWorkerDetails } from "./utils/RenderWorkerDetails";
import { getCategoryDataFromBackend } from "../../utlities/rest/getCategoryDataFromBackend";
import { addLoadingItem, removeLoadingItem } from "./loadingSlice";
import { updateFilter } from "./dashboardSettingSlice";
import { showSnackBar } from "../globalSlice";
import { sleep } from "../../utlities/sleep";
import { v4 as uuidv4 } from "uuid";

const initState = {
  storesInfo: [],
  error: false,
  isError: false,
  cardData: {
    cigaretteCountingData: [],
  },
  storesBelowThresholdDate: "",
  applianceNames: [],
};

export const loadCategoryData = createAsyncThunk(
  //loadCategoryData - loadCategoryData(pageId)
  "categories/loadCategoryData",
  async (pageId, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const state = getState();
    const { lookupDetails, metadataConfig } = state?.authData;
    const pageCategoryDetails =
      state.iotDashboardData?.setting?.pageCategoryDetails;
    const cardData = state.iotDashboardData?.categories?.cardData;
    const {
      store,
      region,
      market,
      al,
      startTimeRange,
      endTimeRange,
      targetTime,
      frankeDevice,
      storeCoverageType,
      performanceType,
    } = state?.iotDashboardData?.setting?.filter;
    const maxStartTimeRange =
      state?.iotDashboardData?.setting?.maxStartTimeRange;
    const { newRelicApiName, components, loadingKey } = pageCategoryDetails;
    const categoryRestDataTemp = CategoryRestDataTemp.getInst();

    dispatch(addLoadingItem(loadingKey));
    let obj = {
      requests: [],
      componentDetails: [],
      id: [],
      sInx: [],
      apiCall: [],
    };
    if (components) {
      for (let component of components) {
        for (let [cIndex, idDetails] of component?.id?.entries()) {
          if (idDetails !== "") {
            const { request, apiCall } = generateRequest(
              component,
              cIndex,
              pageId === "franke" ? startTimeRange : maxStartTimeRange, //because Franke response doesn't have date field, all 30days data is not needed
              targetTime,
              store,
              pageId,
              performanceType,
              storeCoverageType,
              region,
              market,
              al,
              metadataConfig
            );
            obj.requests.push(request);
            obj.componentDetails.push(component);
            obj.id.push(idDetails);
            obj.sInx.push(cIndex);
            obj.apiCall.push(apiCall);
          }
        }
      }
    }

    const { requests, componentDetails, sInx } = obj;

    let parsedResult = categoryRestDataTemp.get({
      requestCalls: JSON.stringify(requests),
      newRelicApiName,
      startTimeRange,
      endTimeRange,
      frankeDevice: pageId === "franke" ? frankeDevice : "",
    });

    if (parsedResult) {
      if (pageId === "franke") {
        dispatch(
          updateCategoryData({
            key: "applianceNames",
            data: parsedResult?.applianceNames,
          })
        );
        dispatch(
          updateFilter({
            frankeDevice: parsedResult?.frankeDevice,
          })
        );
      }
      dispatch(removeLoadingItem(loadingKey));
      return fulfillWithValue({
        cardData: parsedResult?.cardData,
        isError: parsedResult?.cardData?.error,
      });
    }
    let restResult;
    if (region.length <= 2 && store.length <= 30) {
      restResult = await getCategoryDataFromBackend(
        pageCategoryDetails,
        obj,
        dispatch
      );
    } else {
      dispatch(
        showSnackBar({
          severity: "warning",
          message:
            "Cannot select more than 2 regions or 30 stores, please adjust your filter",
        })
      );
    }

    if (restResult) {
      const applianceNames = await worker("filterApplianceName", {
        rawDataMap: restResult,
      });

      const filterCategoryData = await Promise.all(
        componentDetails?.map(async (component, index) => {
          let data =
            component?.isSingleDayData ||
            component?.isLatestData ||
            component?.isLast7DaysData
              ? restResult[index]
              : await worker("filterRawCategoryData", {
                  rawData: restResult[index],
                  startTimeRange: startTimeRange,
                  endTimeRange: endTimeRange,
                  frankeDevice:
                    frankeDevice && applianceNames?.includes(frankeDevice)
                      ? frankeDevice
                      : applianceNames[0],
                });
          return data;
        })
      );

      if (pageId === "franke") {
        dispatch(
          updateCategoryData({
            key: "applianceNames",
            data: pageId === "franke" ? applianceNames : undefined,
          })
        );
        if (Array.isArray(applianceNames))
          dispatch(
            updateFilter({
              frankeDevice:
                frankeDevice && applianceNames?.includes(frankeDevice)
                  ? frankeDevice
                  : applianceNames[0],
            })
          );
      }

      let parsedData = await Promise.all(
        componentDetails?.map(async (component, index) => {
          let data = await RenderWorkerDetails({
            componentDetails: component,
            sIndex: sInx[index],
            cardData: filterCategoryData[index],
            lookupDetails,
            pageId,
            storeCoverageType,
            performanceType,
          });
          return data;
        })
      );
      parsedData = Object.assign({}, ...parsedData);

      if (!parsedData && newRelicApiName) {
        dispatch(removeLoadingItem(loadingKey));
        return rejectWithValue();
      }

      categoryRestDataTemp.set(
        {
          requestCalls: JSON.stringify(requests),
          newRelicApiName,
          startTimeRange,
          endTimeRange,
          frankeDevice:
            pageId === "franke"
              ? frankeDevice && applianceNames?.includes(frankeDevice)
                ? frankeDevice
                : applianceNames[0]
              : "",
        },
        {
          cardData: { ...cardData, ...parsedData, error: false },
          frankeDevice:
            pageId === "franke"
              ? frankeDevice && applianceNames?.includes(frankeDevice)
                ? frankeDevice
                : applianceNames[0]
              : "",
          applianceNames: pageId === "franke" ? applianceNames : undefined,
        }
      );

      dispatch(removeLoadingItem(loadingKey));
      return fulfillWithValue({
        cardData: { ...cardData, ...parsedData, error: false },
        isError: false,
      });
    } else if (newRelicApiName) {
      dispatch(removeLoadingItem(loadingKey));
      return rejectWithValue();
    } else return rejectWithValue();
  }
);

export const powerCycleTimeoutThunk = createAsyncThunk(
  "categories/powerCycleTimeout",
  async (
    { deviceId },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    const timeoutId = uuidv4().toString();
    dispatch(
      updateCategoryData({
        key: `powerCycleRestartStatus-${deviceId}`,
        data: { timeoutId: timeoutId },
      })
    );
    await sleep(120000);
    const state = getState();
    const authData = state?.iotDashboardData?.categories;
    const isOnline =
      authData[`powerCycleStatus-${deviceId}`]?.[0]?.isActive;
    if (!isOnline) {
      const currentTimeoutId =
        authData[`powerCycleRestartStatus-${deviceId}`].timeoutId;
      if (currentTimeoutId === timeoutId) {
        dispatch(
          updateCategoryData({
            key: `powerCycleRestartStatus-${deviceId}`,
            data: null,
          })
        );
        dispatch(
          showSnackBar({
            severity: "warning",
            message:
              "Device is currently not responsive; please try again after few minutes",
          })
        );
      }
    }
  }
);

const categorySlice = createSlice({
  name: "categories",
  initialState: initState,
  reducers: {
    updateCategoryData: (state, action) => {
      //UPDATE_CATEGORY_DATA
      state[action.payload?.key] = action.payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCategoryData.fulfilled, (state, action) => {
      state.cardData = action.payload?.cardData;
      state.isError = action.payload?.isError;
    });
    builder.addCase(loadCategoryData.rejected, (state, action) => {
      state.cardData = { ...state.cardData, error: true };
      state.isError = true;
    });
  },
});

export const { updateCategoryData } = categorySlice.actions;
export default categorySlice.reducer;
