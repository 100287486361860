import { getMainComponentDetails } from "./getMainComponentDetails";
import { formatDateToSwedenFormat } from "../../../utlities/formatTime"
import { getDateRange } from "../../../utlities/dateCalculation";
// import metadataConfig from "../../../actions/metadata.json";

export const generateRequest = (
  component,
  cIndex,
  dateEnqueued,
  targetTime,
  storeIds,
  pageId,
  performanceType,
  storeCoverageType,
  region,
  market,
  al,
  metadataConfig
) => {
  let { yAxisMetrics, xAxis, yAxis, title, threshold } =
    getMainComponentDetails(component);
  let yAxisMetric =
    yAxisMetrics?.length > 0 ? yAxisMetrics[cIndex] : "";
  yAxis = yAxis?.length > 0 ? yAxis[cIndex] : "";
  xAxis = xAxis?.length > 0 ? xAxis[cIndex] : "";
  xAxis = xAxis === "date_enqueued" ? "date" : xAxis;

  let { request, apiCall } = structuredClone(
    metadataConfig[`${pageId}-${yAxis}`] || metadataConfig[`${pageId}-${title}`]
  );

  if (request?.isChartMetric) {
    request["chart_metric"] = [{
      "x_axis": xAxis,
      "metric": yAxisMetric,
      "y_axis": yAxisMetric
    }]
    if (component?.isLatestData)
      request["chart_metric"] = [{
          ...request["chart_metric"][0],
          metric_period: "latest"

      }]
    delete request.isChartMetric
  }

  if (component?.isSingleDayData && request?.filters) {
    request["filters"]["date_enqueued"] = {
      value: formatDateToSwedenFormat(targetTime),
      operator: "=",
    };
    request["filters"]["store_id"] = storeIds;
  } else if (request?.filters) {
    if (pageId !== "power_cycle") {
      if (component?.isLatestData)
        delete request.filters.date_enqueued
      else if (component?.isLast7DaysData)
        request["filters"]["date_enqueued"] =
          formatDateToSwedenFormat(getDateRange("LAST 7 DAYS"));
      else if (apiCall !== "getDynamicNotificationData") //720 hrs is set for getDynamicNotificationData
        request["filters"]["date_enqueued"] = formatDateToSwedenFormat(dateEnqueued);
    }

    if (pageId === "co2" && threshold && request["filters"]["sensor_data"]) {
      request["filters"]["sensor_data"]["value"] = Number(threshold) * 100
    }
    request["filters"]["store_id"] = storeIds;
  } else if (request?.storeId) {
    request["storeId"] = storeIds;
  }

  if (pageId === "franke" && request?.filters && request?.top) {
    request["top"]["order"] = performanceType;
    if (storeCoverageType === "Stores") {
      request["fields"] = [...request["fields"], "store_id"]
    } else if (storeCoverageType === "Regions") {
      request["filters"]["zone_code"] = region;
      request["fields"] = [...request["fields"], "zone_code"]
    } else if (storeCoverageType === "Markets") {
      request["filters"]["market_code"] = market;
      request["fields"] = [...request["fields"], "market_code"]
    } else if (storeCoverageType === "Areas") {
      request["filters"]["area_leader_name"] = al;
      request["fields"] = [...request["fields"], "area_leader_name"]
    }
  }

  return { request, apiCall };
};
