import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addLoadingItem,
  removeLoadingItem,
  setTicketStatusLoading,
} from "./loadingSlice";
import { updateTicketStatus } from "./notificationSlice";
import continuousGetDataRequest from "../../utlities/rest/continuousGetDataRequest";
import { dateRange } from "../../utlities/dateCalculation";
import RestClient from "../../api/RestClient";
import Request from "../../request/Request";
import { showSnackBar } from "../globalSlice";

const initStartTimeRange = dateRange["LAST 30 DAYS"];
const initEndTimeRange = dateRange["TODAY"]

const initState = {
  currentPage: "store health",
  maxStartTimeRange: dateRange["LAST 30 DAYS"],
  maxEndTimeRange: dateRange["TODAY"],
  filter: {
    region: [],
    market: [],
    al: [],
    store: [],
    storeType: "ALL",
    startTimeRange: initStartTimeRange,
    endTimeRange: initEndTimeRange,
    dateTimeRange: "LAST 30 DAYS",
    performanceType: "DESC",
    selectedPerformanceOrder: "Top Performing",
    storeCoverageType: "Stores",
    targetTime: initEndTimeRange,
    frankeDevice: "",
    performanceOptions: ["Top Performing", "Bottom Performing"],
    dateTimeOptions: ["Last 30 Days", "Last 7 Days", "Last 24 Hours"],
    storeCoverageOptions: ["Regions", "Markets", "Areas", "Stores"],
  },
  historyFilter: [],
  pageCategoryDetails: [],
  pageAlertDetails: []
};

export const setFeedbackCall = createAsyncThunk(
  //setFeedbackApiCall - setFeedbackApiCall({name, lastName, feedback})
  "setting/setFeedbackCall",
  async (
    actionPayload,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    dispatch(addLoadingItem("setFeedback"));
    const request = Request.setFeedback(actionPayload);
    const restResult = await continuousGetDataRequest(
      RestClient.setFeedback,
      request,
      dispatch
    );
    if (restResult) {
      dispatch(
        showSnackBar({
          severity: "success",
          message: "Feedback updated successfully.",
        })
      );
    }
    dispatch(removeLoadingItem("setFeedback"));
  }
);

export const setTicketStatus = createAsyncThunk(
  //setTicketStatus - setTicketStatus({alertId, ticketNumber, status})
  "setting/setTicketStatus",
  async (
    actionPayload,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    dispatch(setTicketStatusLoading(true));
    const request = Request.setTicketStatus(actionPayload);
    const restResult = await continuousGetDataRequest(
      RestClient.setTicketStatus,
      request,
      dispatch
    );
    if (restResult) {
      dispatch(
        updateTicketStatus({
          alertId: actionPayload?.alertId,
          status: actionPayload?.status,
        })
      );
      dispatch(
        showSnackBar({
          severity: "success",
          message: "Ticket Status updated successfully.",
        })
      );
    }
    dispatch(setTicketStatusLoading(false));
  }
);

const dashboardSettingSlice = createSlice({
  name: "setting",
  initialState: initState,
  reducers: {
    updateIoTDashboardData: (state, action) => {
      //UPDATE_IOT_DASHBOARD_DATA
      state[action.payload?.key] = action.payload?.data;
    },
    updatePageInIotDashboard: (state, action) => {
      //UPDATE_PAGE_IN_IOT_DASHBOARD
      state.currentPage = action.payload;
    },
    updateFilter: (state, action) => {
      //UPDATE_FILTER
      const newFilter = {
        ...state.filter,
        ...action.payload,
      };
      state.filter = newFilter;
    },
    addRollBackFilter: (state, action) => {
      //ADD_ROLL_BACK_FILTER
      const newHistoryFilter = {
        region: state.filter.region,
        market: state.filter.market,
        al: state.filter.al,
        store: state.filter.store,
        ...action.payload,
      };
      state.historyFilter = [...state.historyFilter, newHistoryFilter];
    },
    rollBackFilter: (state, action) => {
      //ROLL_BACK_FILTER
      const historyFilter = state.historyFilter;
      if (historyFilter.length !== 0) {
        const newFilter = {
          ...state.filter,
          ...historyFilter[historyFilter.length - 1],
        };
        const newHistoryFilter = historyFilter.slice(
          0,
          historyFilter.length - 1
        );
        state.filter = newFilter;
        state.historyFilter = newHistoryFilter;
      }
    },
    updateMaxTimeRange: (state, action) => {
      //UPDATE_MAX_TIMERANGE
      const { maxStartTimeRange, maxEndTimeRange } = action.payload;
      state.maxStartTimeRange = maxStartTimeRange;
      state.maxEndTimeRange = maxEndTimeRange;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  updateIoTDashboardData,
  updatePageInIotDashboard,
  updateFilter,
  addRollBackFilter,
  rollBackFilter,
  updateMaxTimeRange,
} = dashboardSettingSlice.actions;
export default dashboardSettingSlice.reducer;
