import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RestClient from "../api/RestClient";
import Request from "../request/Request";
import continuousGetDataRequest from "../utlities/rest/continuousGetDataRequest";
import { showSnackBar } from "../reducers/globalSlice";
import { getUserProfile_graphService } from "../utlities/rest/graphService";
import { updateCategoryData } from "./iotDashboardReducer/categorySlice";
import { getSessionStorageData } from "../utils";
import frontEndConfig from "../actions/frontend-config.json";

const {
  REACT_APP_REDIRECT_URI,
  REACT_APP_ACCESS_CONTROL,
  REACT_APP_TOKEN_GRANT_TYPE,
  REACT_APP_PING_FEDERATE_ENDPOINT,
  REACT_APP_REFRESHTOKEN_GRANT_TYPE,
} = process.env;

const DEFSTATE = {
  contentNotFoundPageDetails: {
    id: "content_not_found",
    name: "content not found",
    pageRouteName: "PageFor404",
    displayInNav: false,
    hocComponents: [
      {
        componentName: "IotDashboardWrap",
        pageRouteName: "IotDashboardWrap",
      },
      {
        componentName: "WorkbenchWrap",
        pageRouteName: "workbenchWrap",
      },
    ],
    components: [],
  },
  uiDynamicConfig: {},
  getLookupData: {},
  metadataConfig: {},
  loginLoaderValue: false,
  versionConfig: []
};

export const getVersionConfig = createAsyncThunk(
  "authData/getVersionConfig",
  async (params, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const res = await continuousGetDataRequest(
      RestClient.getVersionConfig,
      params,
      dispatch,
      false,
      true
    );
    if (res) return fulfillWithValue(res);
    else return rejectWithValue();
  }
);

export const getConfig = createAsyncThunk(
  //callConfigApi - callConfigApi({})
  "authData/getConfig",
  async (params, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const res = await continuousGetDataRequest(
      RestClient.getConfigDetails,
      params,
      dispatch,
      false,
      true
    );
    if (res) return fulfillWithValue(res);
    else return rejectWithValue();
    // const res = {
    //   frontend_config: frontEndConfig
    // }
    // return fulfillWithValue(res);
  }
);

export const getCategoriesData = createAsyncThunk(
  //getCategoriesDataApi - getCategoriesDataApi({reduxKey, apiFunc, params, navError, cb})
  "authData/getCategoriesData",
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument //thunkAPI is the second argument
    const res = await continuousGetDataRequest(
      RestClient[obj?.apiFunc],
      obj?.params || {},
      dispatch,
      false,
      obj?.navError
    );
    if (res) {
      if (obj?.reduxKey)
        dispatch(updateCategoryData({ key: obj?.reduxKey, data: res }));
      if (obj?.cb) obj.cb();
    }
  }
);

export const getData = createAsyncThunk(
  //getDataApi - getDataApi({reduxKey, apiFunc, params, navError, cb})
  "authData/getData",
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const res = await continuousGetDataRequest(
      RestClient[obj?.apiFunc],
      obj?.params || {},
      dispatch,
      false,
      obj?.navError
    );
    if (res) {
      if (obj?.reduxKey) dispatch(setData({ key: obj?.reduxKey, data: res }));
      if (obj?.cb) obj.cb();
    }
  }
);

export const setUserPersonaApi = createAsyncThunk(
  //setUserPersonaApi - setUserPersonaApi({email, userPersona, userPersonaConfig, cb})
  "authData/setUserPersonaApi",
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const state = getState();
    const { roleIdDetails, geoConfigs } = state.authData;
    const { email, userPersona, userPersonaConfig, cb } = obj;
    const role = roleIdDetails.find((role) => role?.name === userPersona);
    const request = Request.setUserPersona(email, role?.id, userPersonaConfig);
    const res = await continuousGetDataRequest(
      RestClient.setUserPersona,
      request,
      dispatch,
      false,
      false
    );
    if (res) {
      const personaConfig =
        userPersonaConfig === "all" ? geoConfigs : userPersonaConfig;
      dispatch(setData({ key: "userPersonaConfig", data: personaConfig }));
      cb();
    }
  }
);

export const setConfigData = createAsyncThunk(
  //setConfigData - setConfigData(formData)
  "authData/setConfigData",
  async (
    obj,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    const state = getState();
    const request = {
      frontend_config: obj?.frontend_config,
      backend_config: obj?.backend_config,
      version: obj?.version,
    };
    // const request = {
    //   frontend_config: frontEndConfig,
    //   backend_config: obj?.backend_config,
    //   version: 13,
    // };
    const res = await continuousGetDataRequest(
      RestClient.setConfigData,
      request,
      dispatch,
      false,
      false
    );
    if (res)
      dispatch(
        showSnackBar({
          severity: "success",
          message: "Updated Config Successfully",
        })
      );
  }
);

export const callLoginApi = createAsyncThunk(
  //callLoginApi - callLoginApi({request, user, isAuthenticated, cb})
  "authData/callLoginApi",
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const { request, user, isAuthenticated, cb } = obj;
    const res = await continuousGetDataRequest(
      RestClient.azureLogin,
      request,
      dispatch,
      false,
      true,
      true
    );
    if (res) {
      const userPersonaConfig = res?.userConfig?.user_persona?.userPersona
        ? res?.userConfig?.user_persona?.userPersonaConfig &&
          Object.keys(
            res?.userConfig?.user_persona?.userPersonaConfig
          )?.includes("all")
          ? res?.userConfig?.gioConfigData?.geoConfigs
          : res?.userConfig?.user_persona?.userPersonaConfig
        : null;
      dispatch(setData({ key: "sessionId", data: res?.tokens?.token }));
      dispatch(setData({ key: "csrfToken", data: res?.tokens?.csrfToken }));
      if (res?.userConfig?.accessControl?.includes("all")) {
        dispatch(
          setData({ key: "accessControl", data: REACT_APP_ACCESS_CONTROL })
        );
      } else {
        dispatch(
          setData({ key: "accessControl", data: res?.userConfig.accessControl })
        );
      }
      dispatch(
        setData({
          key: "isAdmin",
          data: user?.usergroups ? user?.usergroups === "APP-7IOT-ADMIN" : null,
        })
      );
      dispatch(setData({ key: "isAuthenticated", data: isAuthenticated }));
      dispatch(setData({ key: "email", data: request?.email }));
      dispatch(setData({ key: "name", data: user?.firstName }));
      dispatch(setData({ key: "lastName", data: user?.lastName }));
      dispatch(
        setData({
          key: "geoConfigs",
          data: res?.userConfig?.gioConfigData?.geoConfigs,
        })
      );
      dispatch(
        setData({
          key: "userPersona",
          data: res?.userConfig?.user_persona?.userPersona ?? null,
        })
      );
      dispatch(
        setData({ key: "userPersonaConfig", data: userPersonaConfig ?? null })
      );
      dispatch(setData({ key: "loginLoaderValue", data: false }));
      cb("success");
    }
  }
);

export const setAuthParams = createAsyncThunk(
  //setAuthParams - setAuthParams({isAuthenticated, user, error, cb})
  "authData/setAuthParams",
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const { isAuthenticated, user, error, cb } = obj;

    if (user?.email) {
      let request = Request.azureLogin(user.email);
      dispatch(
        callLoginApi({
          request,
          user,
          isAuthenticated,
          cb,
        })
      );
    } else {
      dispatch(setData({ key: "isAuthenticated", data: isAuthenticated }));
    }
  }
);

export const makeHealthCheck = createAsyncThunk(
  //makeHealthCheck - makeHealthCheck()
  "authData/makeHealthCheck",
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const res = await continuousGetDataRequest(
      RestClient.makeHealthCheck,
      {},
      dispatch,
      false,
      true
    );
    if (res) return fulfillWithValue(res);
    else return rejectWithValue();
  }
);

export const getPingToken = createAsyncThunk(
  //callTokenEndpoint - callTokenEndpoint({code, cb})
  "authData/getPingToken",
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const { code, cb } = obj;
    const params = {
      code: code,
      grant_type: `${REACT_APP_TOKEN_GRANT_TYPE}`,
      redirect_uri: `${REACT_APP_REDIRECT_URI}`,
    };
    const res = await continuousGetDataRequest(
      RestClient.tokenEndpointLogin,
      params,
      dispatch,
      false,
      false,
      false,
      false,
      true
    );
    if (res?.access_token && res?.refresh_token) {
      sessionStorage.setItem(
        "pingFederateToken",
        JSON.stringify(res?.access_token)
      );
      sessionStorage.setItem(
        "pingFederateRefreshToken",
        JSON.stringify(res?.refresh_token)
      );
      setTimeout(() => {
        dispatch(
          getRefreshToken({
            refreshToken: res.refresh_token,
            isUserInfo: false,
            cb: (res) => cb(res),
          })
        );
      }, res.expires_in * 1000)
      cb(res);
    } else return rejectWithValue();
  }
);

export const getUserInfo = createAsyncThunk(
  //userInfoEndpoint - userInfoEndpoint({token, cb})
  "authData/getUserInfo",
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const { token, cb } = obj;
    const params = {};
    const res = await continuousGetDataRequest(
      RestClient.userInfoEndpoint,
      params,
      dispatch,
      false,
      true,
      false,
      true,
      false
    );
    if (res) {
      if (res?.sub) cb(res);
    } else return rejectWithValue();
  }
);

export const revocatePingToken = createAsyncThunk(
  //callRevocateTokenApi - callRevocateTokenApi()
  "authData/revocatePingToken",
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const pingFederateRefreshToken = getSessionStorageData(
      "pingFederateRefreshToken"
    );
    const params = {
      token: pingFederateRefreshToken,
      token_type_hint: `refresh_token`,
    };
    const res = await continuousGetDataRequest(
      RestClient.revocateTokenEndpointLogin,
      params,
      dispatch,
      false,
      false,
      false,
      false,
      true
    );
    if (res !== null) dispatch(callLogoutApi());
    else {
      dispatch(
        setAuthParams({
          isAuthenticated: false,
          user: {},
          error: null,
          cb: (res) => {},
        })
      );
      return rejectWithValue();
    }
  }
);

export const getRefreshToken = createAsyncThunk(
  //getRefreshToken - getRefreshToken({refreshToken, cb})
  "authData/getRefreshToken",
  async (
    actionPayload,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    const { refreshToken, isUserInfo, cb } = actionPayload;
    const params = {
      grant_type: `${REACT_APP_REFRESHTOKEN_GRANT_TYPE}`,
      refresh_token: refreshToken,
    };

    const response = await continuousGetDataRequest(
      RestClient.tokenEndpointLogin,
      params,
      dispatch,
      false,
      false,
      false,
      false,
      true
    );
    if (response !== null && response.access_token && response.refresh_token) {
      sessionStorage.setItem(
        "pingFederateToken",
        JSON.stringify(response?.access_token)
      );
      sessionStorage.setItem(
        "pingFederateRefreshToken",
        JSON.stringify(response?.refresh_token)
      );
      if (isUserInfo) cb("recall");
      else {
        await getUserProfile_graphService(
          response.access_token,
          response.refresh_token,
          dispatch,
          (res) => {
            if (res.user && res.user.email) {
              let request = Request.azureLogin(res.user.email);
              let user = {
                ...res?.user,
                token: res.token,
                refreshToken: res.refreshToken,
              };
              dispatch(
                callLoginApi({
                  request,
                  user,
                  isAuthenticated: res?.isAuthenticated,
                  cb: (res) => {
                    setTimeout(() => {
                      dispatch(revocatePingToken({}))
                    }, response.expires_in * 1000)
                    return res === "success" ? cb("recall") : ""
                  },
                })
              );
            } else if (!res.token || res.error) {
              if (res.error) {
                dispatch(revocatePingToken({}));
              } else if (!res.token) {
                dispatch(
                  setAuthParams({
                    isAuthenticated: false,
                    user: {},
                    error: null,
                    cb: (res) => {},
                  })
                );
              }
            }
          }
        );
      }
    } else {
      dispatch(revocatePingToken({}));
    }
  }
);

export const refreshTokenCall = createAsyncThunk(
  //refreshTokenCall - refreshTokenCall({errorMessage, cb})
  "authData/refreshTokenCall",
  async (
    actionPayload,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    const { errorMessage, isUserInfo, cb } = actionPayload;
    let refreshToken = getSessionStorageData("pingFederateRefreshToken");
    if (errorMessage?.message === "Token Expired" && refreshToken) {
      dispatch(
        getRefreshToken({
          refreshToken,
          isUserInfo,
          cb: (res) => cb(res),
        })
      );
    } else {
      cb("logout");
      dispatch(revocatePingToken({}));
    }
  }
);

export const authSlice = createSlice({
  name: "authData",
  initialState: DEFSTATE,
  reducers: {
    setData: (state, action) => {
      //GET_DATAS
      state[action.payload?.key] = action.payload?.data;
    },
    setDispatchParams: (state, action) => {
      const dispatchParams = action.payload;
      if (dispatchParams.length > 0) {
        dispatchParams.forEach((data) => {
          state[data?.name] = data?.value;
        });
      }
    },
    getRoleDetails: (state, action) => {
      const userGroupDetails = action.payload;
      const role =
        userGroupDetails === "APP-7IOT-ADMIN" ? "adminRole" : "userRole";
      if (role === "adminRole")
        state.userRoles = [
          "Dashboard",
          "Administration",
          "Devices",
          "Users",
          "Alerts",
          "Reports",
          "Rules",
          "Analytics",
        ];
      else state.userRoles = ["Dashboard", "Reports", "Alerts"];
    },
    callLogoutApi: (state, action) => {
      sessionStorage.clear();
      let singOffUrl = `${REACT_APP_PING_FEDERATE_ENDPOINT}/idp/startSLO.ping`;
      singOffUrl = singOffUrl.concat(
        `?TargetResource=${REACT_APP_REDIRECT_URI}&InErrorResource=${REACT_APP_REDIRECT_URI}`
      );
      window.location.assign(singOffUrl);
    },
    // testAsyncDispatch:(state)=>{
    //     state.test = true
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(getVersionConfig.fulfilled, (state, action) => {
      const payloadData = action.payload;
      state.versionConfig = payloadData;
    });

    builder.addCase(getConfig.fulfilled, (state, action) => {
      const payloadData = action.payload;
      state.uiDynamicConfig = payloadData?.frontend_config;
      state.metadataConfig = payloadData?.frontend_config?.meta_data;
    });

    builder.addCase(makeHealthCheck.fulfilled, (state, action) => {
      window.location.href = "/";
    });

    builder.addCase(getPingToken.rejected, (state, action) => {
      sessionStorage.clear();
    });

    builder.addCase(getUserInfo.rejected, (state, action) => {
      sessionStorage.clear();
    });

    builder.addCase(revocatePingToken.rejected, (state, action) => {
      sessionStorage.clear();
    });
  },
});

export const { setData, setDispatchParams, getRoleDetails, callLogoutApi } =
  authSlice.actions;
export default authSlice.reducer;
