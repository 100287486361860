export const getMainComponentDetails = (componentDetails) => {
  const { isChart, detailTable, isLogData, isTitle, powerCycle } =
    componentDetails;
  let parserName = "";
  let yAxisMetrics = [];
  let xAxis = [];
  let yAxis = [];
  let title = "";
  let item = null;
  let isLegend = false;
  let threshold;
  if (isChart) {
    item = componentDetails?.chartDetails;
    const { chartType, chartSubType, isWeekData } = item;
    if (chartType === "lineChart") parserName = "lineChartDataParser";
    else if (chartType === "barChart" && (chartSubType === "Data Chart" || chartSubType === "Data+Description Chart"))
      parserName = "singleDataParser";
    else if (chartType === "barChart" && isWeekData && chartSubType === "Data+PerformanceTable Chart")
      parserName = "weekDataParser";
    else if (chartType === "barChart" && chartSubType === "Data+PerformanceTable Chart") parserName = "comparisonChartParser";
    else if (chartType === "pieChart" && chartSubType === "Data Chart") parserName = "pieChartDataParser";
    else if (chartType === "pieChart" && chartSubType === "Data+PerformanceTable Chart") parserName = "comparisonChartParser";
    isLegend = item?.isLegend
    threshold = item?.threshold
  } else if (detailTable) {
    item = componentDetails?.tableDetails;
    title = item?.tableTitle;
    parserName = "tableDataParser";
    threshold = item?.threshold
  } else if (isLogData) {
    item = componentDetails?.logDetails;
    parserName = "tempLogDataParser";
  } else if (isTitle) {
    item = componentDetails?.titleDetails;
    title = "connectionstatus";
    parserName = "connectionStatusParser";
  } else if (powerCycle) {
    item = componentDetails?.powerCycleDetails;
    title = "";
    parserName = null;
  }
  yAxisMetrics = powerCycle ? ["latest_datetime"] : item?.yAxisMetrics;
  xAxis = powerCycle ? ["sensor_data"] : item?.xAxis;
  yAxis = powerCycle ? ["latest_datetime"] : item?.yAxis;
  return { parserName, yAxisMetrics, xAxis, yAxis, title, isLegend, threshold };
};  