export function formatDateToUSFormat(time) { // 1. convert yyyy-mm-dd -> yyyy/mm/dd, 2. convert to date format
    return new Date(time?.split(' ')[0].replace(/-/g, '\/').replace(/T/, ' ').replace(/Z/, ' '));
}

export function formatDateToSwedenFormat(date) { // mm/dd/yyyy -> yyyy-mm-dd
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}