/* eslint-disable */
import "./App.css";
import React, { useState, Fragment, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { getUserProfile_graphService } from "./utlities/rest/graphService";
import {
  getConfig,
  getData,
  setAuthParams,
  getPingToken,
  setData,
} from "./reducers/authSlice";
import "./assets/index.css";
import SnackBar from "./components/SnackBar";
import { getSessionStorageData } from "./utils";
import Footer from "./components/Footer/index.js";
// import uiDynamicConfig from "./actions/iot-ui-dynamic-config.json";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const RolesPage = lazy(() => import("./pages/RolesPage"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const AccessRequestApproval = lazy(() =>
  import("./pages/AccessRequestApproval")
);
const NewLogin = lazy(() => import("./pages/NewLogin"));
const CMSPage = lazy(() => import("./pages/7CMS"));

const {
  REACT_APP_PING_FEDERATE_ENDPOINT,
  REACT_APP_ID,
  REACT_APP_REDIRECT_URI,
  REACT_APP_AUTH_RESPONSE_TYPE,
  REACT_APP_AUTH_SCOPE,
} = process.env;


let materialTheme = createTheme({
  palette: {
    primary: {
      dark: '#0c5e49',
      main: '#107f62',
      light: '#5da694',
      contrastText: '#fff'
    }
  }
})

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
};

const App = () => {
  const dispatch = useDispatch();
  const { uiDynamicConfig } = useSelector((state) => state.authData);
  const [state, setState] = useState({
    isAuthenticated: false,
    user: {},
    error: null,
    userRoles: [],
    constructorHasRun: false,
  });

  const setUserDetails = (res) => {
    setState({
      ...state,
      isAuthenticated: res?.isAuthenticated,
      user: res?.user,
      error: res?.error,
    });
    if (res?.token && res?.refreshToken && !res.error) {
      dispatch(
        setAuthParams({
          isAuthenticated: res?.isAuthenticated,
          user: {
            ...res?.user,
            token: res.token,
            refreshToken: res.refreshToken,
          },
          error: null,
          cb: (res) => {
            if (window.location.pathname !== "/error") {
              dispatch(getConfig({}));
              dispatch(
                getData({
                  reduxKey: "lookupDetails",
                  apiFunc: "getLookupData",
                  params: {},
                  navError: false,
                })
              );
            }
          },
        })
      );
    } else if (!res?.token || res?.error) {
      if (res?.error) {
        // logout();
      } else if (!res?.token) {
        dispatch(
          setAuthParams({
            isAuthenticated: false,
            user: {},
            error: null,
            cb: (res) => { },
          })
        );
      }
    }
  };

  const getUserProfile = async (accessToken, refreshToken) => {
    await getUserProfile_graphService(
      accessToken,
      refreshToken,
      dispatch,
      (res) => {
        setUserDetails(res);
      }
    );
  };

  const login = async (code) => {
    dispatch(
      getPingToken({
        code,
        cb: async (res) => {
          if (res && res.access_token && res.refresh_token) {
            await getUserProfile(res.access_token, res.refresh_token);
          }
        },
      })
    );
  };
  const loginButtonOnclick = () => {
    window.location.href = `${REACT_APP_PING_FEDERATE_ENDPOINT}/as/authorization.oauth2?client_id=${REACT_APP_ID}&redirect_uri=${REACT_APP_REDIRECT_URI}&response_type=${REACT_APP_AUTH_RESPONSE_TYPE}&scope=${REACT_APP_AUTH_SCOPE}`;
  };

  useConstructor(() => {
    dispatch(
      setAuthParams({
        isAuthenticated: false,
        user: {},
        error: null,
        cb: (res) => { },
      })
    );

    if (
      getSessionStorageData("pingFederateToken") &&
      getSessionStorageData("pingFederateRefreshToken")
    ) {
      dispatch(setData({ key: "loginLoaderValue", data: true }));
      getUserProfile(
        getSessionStorageData("pingFederateToken"),
        getSessionStorageData("pingFederateRefreshToken")
      );
    } else if (sessionStorage.length) {
      sessionStorage.removeItem("pingFederateToken");
      sessionStorage.removeItem("pingFederateRefreshToken");
    }
  });

  const renderRoute = (routeItem, index) => {
    const DynamicComponent = lazy(() =>
      import(`./pages/${routeItem.pageRouteName}`)
    );
    return (
      <React.Fragment key={index}>
        {routeItem.link.map((link, index) => (
          <Route
            key={index}
            path={link}
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <DynamicComponent />
                <Footer />
              </Suspense>
            }
          />
        ))}
      </React.Fragment>
    );
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SnackBar />
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/roles"
            element={
              <ThemeProvider theme={materialTheme}>
                <RolesPage />
                <Footer />
              </ThemeProvider>
            }
          />
          <Route exact path="/error" element={
            <>
              <ErrorPage />
              <Footer />
            </>
          } />
          <Route exact path="/7CMS" element={
            <>
              <CMSPage />
              <Footer />
            </>
          } />
          <Route
            exact
            path="/accessrequestapproval/:accessRequestId/:status/:actionBy"
            element={
              <>
                <AccessRequestApproval />
                <Footer />
              </>
            }
          />
          {uiDynamicConfig?.routes?.map((item, index) =>
            renderRoute(item, index)
          )}
          <Route
            exact
            path="/*"
            element={
              <>
                <NewLogin
                  authButtonMethod={login}
                  loginButtonOnclick={loginButtonOnclick}
                />
                <Footer isLoginPge={true} />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};
export default App;
