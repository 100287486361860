import React from "react";
import { Box } from "@mui/material";

const Footer = (props) => {
    let updateStyles = props?.isLoginPge ? {
        position: "fixed",
        bottom: "5%",
    } : {}
  return (    
  <Box
    width={"100%"}
    style={{
      display: "flex",
      justifyContent: "center",
      height: "10%",
      marginTop: "2%",
      ...updateStyles
    }}
  >
    <footer style={styles.footer}>IoT Insights: A product of 7NEXT</footer>
  </Box>
  );
};

const styles = {
  footer: {    
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    fontFamily: "Roboto",
    fontSize: "13px",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "15.23px",
    textAlign: "right",
    zIndex: 1000,
    color: "#2C2F3599",
    width: "90%",
  },
};

export default Footer;