function getPreviousDay(num) {
  const day = new Date();
  day.setDate(day.getDate() - num);
  day.setHours(0,0,0, 0)
  return day;
}

export const dateRange = {
  TODAY: getPreviousDay(0).toLocaleDateString("en-US"),
  "PREVIOUS DAY": getPreviousDay(1).toLocaleDateString("en-US"),
  "LAST 24 HOURS": getPreviousDay(1).toLocaleDateString("en-US"),
  "LAST 7 DAYS": getPreviousDay(7).toLocaleDateString("en-US"),
  "LAST 30 DAYS": getPreviousDay(30).toLocaleDateString("en-US"),
};

export const getDateRange = (key) => {
  const upperCaseKey = key.toUpperCase();
  if (dateRange.hasOwnProperty(upperCaseKey)) {
    return dateRange[upperCaseKey];
  } else {
    return null;
  }
};