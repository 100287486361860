export function getErrorAttributesByAxiosError(err) {
    const errorAttributes = {}
    if (err.config) {
      if (err.config.headers) {
        errorAttributes.reqHeaders = JSON.stringify(err.config.headers)
      }
      if (err.config.url) {
        errorAttributes.url = err.config.url
      }
      if (err.config.data) {
        errorAttributes.reqBody = err.config.data
      }
      if (err.config.method) {
        errorAttributes.method = err.config.method
      }
    }
    if (err.response) {
      if (err.response.headers) {
        errorAttributes.resHeaders = JSON.stringify(err.response.headers)
      }
      if (err.response.status) {
        errorAttributes.statusCode = err.response.status
      }
      if (err.response.data) {
        errorAttributes.resData = err.response.data
      }
    }
    return errorAttributes
  }