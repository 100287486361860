import {worker} from "../../worker";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AllNotificationTemp from "../../Temp/AllNotificationTemp";
import {getNotificationFromBackend} from "../../utlities/rest/getNotificationFromBackend";
import continuousGetDataRequest from "../../utlities/rest/continuousGetDataRequest";
import RestClient from "../../api/RestClient";
import {addLoadingItem, removeLoadingItem} from "./loadingSlice";
import { showSnackBar } from "../globalSlice";
import {formatDateToSwedenFormat} from "../../utlities/formatTime"

/*
DetailedNotification - IotDashboard Main Page elaborated notifications
Notification - Notification Page elaborated notifications
'' - notification data for table in Error codes and Ticket Category
*/

const initState = {
    notificationFilter: {
        sortBy: "A-Z",
        allEquipmentType: [],
        equipmentType: [],
        sortByDetailedNotification: "A-Z",
        allEquipmentTypeDetailedNotification: [],
        equipmentTypeDetailedNotification: [],       
        sortByNotification: "A-Z",
        allEquipmentTypeNotification: [],
        equipmentTypeNotification: []
    },
    allNotifications: [],
    notifications: [],
    filteredNotifications: [],
    filteredNotificationsDetailedNotification: [],
    filteredNotificationsNotification: [],
    syncTime: '',
    notificationReloadTrigger: 0,
    notificationModal: null
}

export const loadAllNotifications = createAsyncThunk(
  //loadAllNotifications - loadAllNotifications(pageId)
  "notificationData/loadAllNotifications",
  async (pageId, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
        const state = getState();
        const pageAlertDetails =
          state.iotDashboardData?.setting?.pageAlertDetails;
        const {
          store,
          region,
          startTimeRange, 
          endTimeRange
        } = state?.iotDashboardData?.setting?.filter
        const allNotificationTemp = AllNotificationTemp.getInst()

        dispatch(addLoadingItem('allNotifications'))
        const request = { 
          filters: { 
            startDuration: 72,
            durationType: "HOURS",
            store_id: store,
          },
          deviceType: {
            "sensor": pageAlertDetails?.filter(item => item.deviceType === "sensor")?.map(item => item?.Type),
            "appliance": pageAlertDetails?.filter(item => item.deviceType === "appliance")?.map(item => item?.Type)
          },
        }
    
        let parsedResult = allNotificationTemp.get({
            requestCalls: JSON.stringify(request),
            newRelicApiName: "Notification API",
            startTimeRange
        })
        if (parsedResult) {
            dispatch(removeLoadingItem('allNotifications'));
            return fulfillWithValue({
                equipmentTypes: parsedResult?.equipmentTypes,
                allNotifications: parsedResult?.allNotifications,
            });
        }

        let rawNotifications;
        if (region.length <= 2 && store.length <= 30) {
          rawNotifications = await getNotificationFromBackend(request, dispatch)
        } else {
          dispatch(
            showSnackBar({
              severity: "warning",
              message:
                "Cannot select more than 2 regions or 30 stores, please adjust your filter",
            })
          );
        }
        if(rawNotifications) {
          const {processedNotifications, equipmentTypes} = await worker('processNotifications', rawNotifications)
      
          const allNotifications = await worker('filterNotificationsByTimeRange', {
            notifications: processedNotifications,
            startTimeRange: startTimeRange,
            endTimeRange: endTimeRange
          })
    
          allNotificationTemp.set({
              requestCalls: JSON.stringify(request),
              newRelicApiName: "Notification API",
              startTimeRange
          }, {
            allNotifications,
            equipmentTypes: Array.from(equipmentTypes)
          })

          dispatch(removeLoadingItem('allNotifications'));
          return fulfillWithValue({
              equipmentTypes: Array.from(equipmentTypes),
              allNotifications: allNotifications,
          });
        } else {
          return rejectWithValue()
        }
  }
);

export const loadFilteredNotification = createAsyncThunk(
  //loadFilteredNotification - loadFilteredNotification(source)
  "notificationData/loadFilteredNotification",
  async (source, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
        const state = getState();
        const pageAlertDetails =
          state.iotDashboardData?.setting?.pageAlertDetails;
        const allNotifications = state?.iotDashboardData?.notificationData?.allNotifications
        const sortBy = state?.iotDashboardData?.notificationData?.notificationFilter[`sortBy${source}`]
        const equipmentType = state?.iotDashboardData?.notificationData?.notificationFilter[`equipmentType${source}`]
        const {store, startTimeRange} = state?.iotDashboardData?.setting?.filter
        const maxStartTimeRange = state?.iotDashboardData?.setting?.maxStartTimeRange
        const allNotificationTemp = AllNotificationTemp.getInst()

        dispatch(addLoadingItem('filteredNotifications'))
        const request = { 
          filters: { 
            date_enqueued: formatDateToSwedenFormat(maxStartTimeRange),
            store_id: store,
          },
          error_type: pageAlertDetails?.map(item => item?.Type)
        }
    
        let parsedResult = allNotificationTemp.get({
            requestCalls: JSON.stringify(request),
            newRelicApiName: `Filtered Notification-${source}`,
            equipmentType: JSON.stringify(equipmentType),
            sortBy: JSON.stringify(sortBy),
            startTimeRange
        })
        if (parsedResult) {
            dispatch(removeLoadingItem('filteredNotifications'));
            return fulfillWithValue({
                filteredNotification: parsedResult,
                source,
            });
        }

        let newFilteredNotifications = [...allNotifications]
        newFilteredNotifications = await worker('filterNotificationsByEquipmentType', {
          notifications: newFilteredNotifications,
          equipmentType: equipmentType
        })
        newFilteredNotifications = await worker('sortNotifications', {
          notifications: newFilteredNotifications,
          sortBy: sortBy
        })
    
        allNotificationTemp.set({
            requestCalls: JSON.stringify(request),
            newRelicApiName: `Filtered Notification-${source}`,
            equipmentType: JSON.stringify(equipmentType),
            sortBy: JSON.stringify(sortBy),
            startTimeRange
        }, newFilteredNotifications)

        dispatch(removeLoadingItem('filteredNotifications'));
        return fulfillWithValue({
            filteredNotification: newFilteredNotifications,
            source,
        });
  }
);

export const createTicket = createAsyncThunk(
  //createTicket - createTicket({alertId, storeId, title, content, address, errorData, sensor, deviceId, deviceName})
  "notificationData/createTicket",
  async (actionPayload, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const {alertId, storeId, title, content, address, errorData, sensor, deviceId, deviceName} = actionPayload
    dispatch(addLoadingItem('createTicket'))
    const allNotificationTemp = AllNotificationTemp.getInst()
    let result
    try {
      result = await continuousGetDataRequest(
        RestClient.createTicketForNotification,
        {
          alert_id: alertId,
          description: content,
          short_description: title,
          store_id: storeId,
          error_data: errorData,
          address: address,
          sensor: sensor,
          device_id: deviceId,
          device_name: deviceName
        },
        dispatch,
        false,
        false,
        false,
        false,
        false,
        true
      )
    } catch (e) {
      throw e
    } finally {
      dispatch(removeLoadingItem('createTicket'))
    }
    if (result) {
        dispatch(showSnackBar({
          severity: "success", 
          message: `Ticket ${result?.ticket_number} has been created`
        }))
        allNotificationTemp.clear()
        dispatch(notificationReload({
          alertId, 
          ticketNumber: result?.ticket_number, 
          isDismissed: false, 
          resolved: false
        }))
    }
  }
);

export const dismissTicket = createAsyncThunk(
  //dismissTicket - dismissTicket({deviceId, alertId, isResolved})
  "notificationData/dismissTicket",
  async (actionPayload, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const {deviceId, alertId, isResolved} = actionPayload
    const action = isResolved ? "resolved" : "dismissed";
    dispatch(addLoadingItem("dismissTicket"));
    const allNotificationTemp = AllNotificationTemp.getInst();
    const result = await continuousGetDataRequest(
      RestClient.dismissTicketForNotification,
      {
        device_id: deviceId,
        alert_id: alertId,
        is_resolved: isResolved,
      },
      dispatch
    );
    dispatch(removeLoadingItem("dismissTicket"));
    if (result) {
        dispatch(showSnackBar({
          severity: "success", 
          message: `Alert has been ${action}`
        }))
        allNotificationTemp.clear();
        dispatch(notificationReload({
          alertId, 
          ticketNumber: null, 
          isDismissed: !isResolved, 
          resolved: isResolved
        }))
    }
  }
);

const notificationSlice = createSlice({
    name:'notificationData',
    initialState: initState,
    reducers: {
        updateNotificationFilter: (state, action) => { //UPDATE_NOTIFICATION_FILTER
          const {sortBy, equipmentType, source} = action.payload
          const newNotificationFilter = {
              ...state.notificationFilter,
              [`sortBy${source}`]: sortBy ? sortBy : state.notificationFilter[`sortBy${source}`],
              [`equipmentType${source}`]: equipmentType ? equipmentType : state.notificationFilter[`equipmentType${source}`]
          }
          state.notificationFilter = newNotificationFilter
        },
        updateFilteredNotifications: (state, action) => { //UPDATE_FILTERED_NOTIFICATIONS
            const {filteredNotification, source} = action.payload
            state[`filteredNotifications${source}`] = filteredNotification
        },
        updateTicketStatus: (state, action) => { //UPDATE_TICKET_STATUS
            const {alertId, status} = action.payload
            const newNotifications = [...state.allNotifications]
            for (const notification of newNotifications) {
                if (notification.Alert_Id === alertId) {
                    notification.Status = status
                }
            }
            state.notifications = newNotifications
        },
        notificationReload: (state, action) => { //NOTIFICATION_RELOAD
            const {alertId, ticketNumber, isDismissed, resolved} = action.payload
            const newNotifications = [...state.allNotifications]
            for (const notification of newNotifications) {
                if (notification.Alert_Id === alertId) {
                    notification.Status = ticketNumber === null ? notification.Status : "success"
                    notification.Ticket_Number = ticketNumber
                    notification.isDismissed = isDismissed
                    notification.resolved = resolved
                }
            }
            state.allNotifications = newNotifications
        },
        setNotificationModal: (state, action) => { //SET_NOTIFICATION_MODAL
            state.notificationModal = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadAllNotifications.fulfilled, (state, action) => {
            const {equipmentTypes, allNotifications} = action.payload
            state.notificationFilter = {
                ...state.notificationFilter,
                allEquipmentType: equipmentTypes,
                equipmentType: equipmentTypes,                    
                allEquipmentTypeNotification: equipmentTypes,
                equipmentTypeNotification: equipmentTypes,
                allEquipmentTypeDetailedNotification: equipmentTypes,
                equipmentTypeDetailedNotification: equipmentTypes
            }
            state.allNotifications = allNotifications
        });
        builder.addCase(loadFilteredNotification.fulfilled, (state, action) => {
            const {filteredNotification, source} = action.payload
            state[`filteredNotifications${source}`] = filteredNotification
        });
    }
})

export const { updateNotificationFilter, updateFilteredNotifications, updateTicketStatus, notificationReload, 
    setNotificationModal } = notificationSlice.actions;
export default notificationSlice.reducer;